import React, { useState, useRef } from "react";
// import { FaanPaymentService } from "../../_services/faanPayment.service";
import { alertActions } from "../../_actions";
import { appConstants } from "../../_constants";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Col,
  Row,
} from "reactstrap";
import "./faan.scss";
import { connect } from "react-redux";

const PaymentSummary = ({
  isOpen,
  toggle,
  outstandingAmount,
  dispatch,
  agencyId,
  amountToPay,
  rrr,
  billerUrl,
  merchantId,
  hash,
  responseUrl,
  formatCurrency,
}) => {
  const [amount, setAmount] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");

  const [getRRR, setGetRRR] = useState("");
  const [rrrDetails, setRRRDetails] = useState("");
  let invoiceLocal = localStorage.getItem("Invoice Number");
  let partPay = localStorage.getItem("Part Amount");
  const formRef = useRef(null);

  const submitRemitaForm = (invoiceDetails) => {
    console.log(invoiceDetails, "Remita Response");
    if (formRef.current) {
      // Set form values dynamically
      formRef.current.merchantId.value = invoiceDetails.merchantId;
      formRef.current.hash.value = invoiceDetails.hash;
      formRef.current.rrr.value = invoiceDetails.rrr;
      formRef.current.responseurl.value = invoiceDetails.responseUrl;

      // Set the form action URL correctly
      formRef.current.action = invoiceDetails.billerUrl;
      // formRef.current.action = "https://login.remita.net/remita/ecomm/finalize.reg"

      // Ensure form submission happens in a user-triggered event
      setTimeout(() => {
        formRef.current.submit();
      }, 500);
    }
  };

  const handleFullPaymentClick = () => {
    submitRemitaForm({
      merchantId,
      hash,
      rrr,
      responseUrl,
      billerUrl,
    });
  };

  console.log(invoiceLocal, "invoiceLocal");
  console.log(agencyId, "agencyId");

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        centered
        className="partial-payment-modal"
      >
        <ModalHeader onClick={toggle} className="invoice-modal-header as-button">
          ← Payment summary
        </ModalHeader>
        <ModalBody>
          <div className="main-popup-information">
            <div className="popup-info">
              <p className="payment-det">Amount to be paid</p>
              <h2 className="info-detail">
                {formatCurrency(amountToPay)}
              </h2>
            </div>
            <div className="popup-info">
              <p className="payment-det">Outstanding</p>
              <h2 className="info-detail">
                {formatCurrency(outstandingAmount)}
              </h2>
            </div>
          </div>

          <div style={{ marginTop: "10%", marginBottom: "5%" }}>
            <div className="terms-of-payment">
              <div className="rrr-container">
                <div className="rrr-title">
                  Remita reference number for payment
                </div>
                <div className="rrr-number">RRR: {rrr}</div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="invoice-modal-footer">
          <Button color="light" onClick={toggle} className="cancel-btn">
            Cancel
          </Button>
          <Button
            color="danger"
            className="proceed-btn"
            onClick={handleFullPaymentClick}
          >
            Make payment
          </Button>
        </ModalFooter>
      </Modal>
      {/* Hidden Form for Remita Payment */}
      <form
        ref={formRef}
        action=""
        name="SubmitRemitaForm"
        method="POST"
        target="_blank"
        style={{ display: "none" }}
      >
        <input name="merchantId" type="hidden" />
        <input name="hash" type="hidden" />
        <input name="rrr" type="hidden" />
        <input name="responseurl" type="hidden" />
        <input type="submit" value="Pay via Remita" />
      </form>
    </>
  );
};
const mapStateToProps = (state) => ({
  alert: state.alert,
  user: state.user,
  requesting: state.requesting,
  userData: state.userData,
  displayGeneralAlert: state.displayGeneralAlert,
});

export default connect(mapStateToProps)(PaymentSummary);
