import React, { useState, useEffect } from "react";
import { alertActions } from "../../_actions";
import { appConstants } from "../../_constants";
import { connect } from "react-redux";
import { Label } from "reactstrap";
import searchLogo from "../../assets/images/searchLogo.png";
import { externalPaymentService } from "../../_services/externalPayment.service";
import InvoiceDetails from "./invoice-details";
import "./record.scss";
import { store } from "react-notifications-component";
import { commonActions } from "../../_actions";

let source;

const FaanPayment = (props) => {
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [isAgencyIdReady, setIsAgencyIdReady] = useState(false);
  const [invoiceError, setInvoiceError] = useState("");
  const { agencyId } = props;
  const agencyDomain = window.location.origin;
  const handlePartPaymentClick = () => {
    setIsModalOpen(true);
    setShowInvoiceDetails(true);
  };

  const searchInvoiceReference = React.useCallback(async () => {
    const { dispatch } = props;
    let payload = {
      invNum: invoiceNumber,
      agencyId: agencyId,
    };
    setIsLoading(true);
    dispatch(alertActions.startRequest());

    try {
      const res = await externalPaymentService.searchInvoiceDetails(payload); // ✅ Only call it here

      dispatch(alertActions.stopRequest());

      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        setInvoiceDetails(response.invoice);
        setPaymentHistory(response.subInvoices);

        // ✅ Show success notification
        store.addNotification({
          title: "Success",
          message: "Invoice details fetched successfully!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        console.error("API Error Response:", response);

        // ✅ Show error notification
        store.addNotification({
          title: "Error",
          message:
            response || "An error occurred while fetching invoice details.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });

        dispatch(
          alertActions.error(
            response.message || "Error fetching invoice details"
          )
        );
      }
    } catch (error) {
      console.error("Error occurred while fetching invoice:", error);

      // ✅ Show error notification for unexpected errors
      store.addNotification({
        title: "Error",
        message: "An unexpected error occurred while fetching invoice details.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

      dispatch(alertActions.error("Error fetching invoice details"));
    } finally {
      setIsLoading(false);
    }
  }, [invoiceNumber, agencyId, props]);

  
  // const handleProceed = React.useCallback(async () => {
  //   if (!invoiceNumber.trim()) {
  //     setInvoiceError("Invoice number is compulsory");
  //     return;
  //   }

  //   setInvoiceError("");

  //   if (!isAgencyIdReady) {
  //     setIsLoading(true);
  //     await new Promise((resolve) => {
  //       const checkAgencyId = () => {
  //         if (isAgencyIdReady) {
  //           resolve();
  //         } else {
  //           setTimeout(checkAgencyId, 100); // Check every 100ms
  //         }
  //       };
  //       checkAgencyId();
  //     });
  //   }
  //   localStorage.setItem("Invoice Number", invoiceNumber);
  //   await searchInvoiceReference();
  // }, [invoiceNumber, isAgencyIdReady]);

  const handleProceed = React.useCallback(async () => {
    if (!invoiceNumber.trim()) {
      setInvoiceError("Invoice number is compulsory");
      return;
    }
  
    setInvoiceError("");
  
    // Check for agencyId readiness but stop after 4 seconds
    const waitForAgencyId = new Promise((resolve, reject) => {
      const startTime = Date.now();
  
      const checkAgencyId = () => {
        if (isAgencyIdReady) {
          resolve(); // ✅ Agency ID is ready, proceed
        } else if (Date.now() - startTime >= 4000) {
          reject(new Error("Network error, please try again")); // ❌ Timeout at 4 seconds
        } else {
          setTimeout(checkAgencyId, 100);
        }
      };
  
      checkAgencyId();
    });
  
    try {
      setIsLoading(true);
      await waitForAgencyId; // Wait for agency ID or timeout
  
      localStorage.setItem("Invoice Number", invoiceNumber);
      await searchInvoiceReference(); // Proceed if agencyId is ready
    } catch (error) {
      store.addNotification({
        title: "Network Error",
        message: "Network error, please try again.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 5000 },
      });
  
      setIsLoading(false);
    }
  }, [invoiceNumber, isAgencyIdReady]);
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setShowInvoiceDetails(false);
  };

  useEffect(() => {
    const payload = {
      domainURL: agencyDomain,
      agencyId: "",
      agencyCode: "",
    };
    props.dispatch(commonActions.getBranding(payload));
  }, [agencyDomain, props.dispatch]);

  useEffect(() => {
    if (props.agencyId) {
      setIsAgencyIdReady(true);
    }
  }, [props.agencyId]);

 

  return (
    <div className="faan-payment-container">
      {!invoiceDetails ? (
        <div className="faan-payment-content">
          <div className="invoice-search">
            <div>
              <img src={searchLogo} alt="Search Logo" />
            </div>
            <div
              style={{
                width: "100%",
                color: "#1F1F1F",
                fontSize: 16,
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              Enter your invoice number below to view details
            </div>
            <div>
              <Label
                style={{
                  color: "rgba(31, 31, 31, 0.80)",
                  fontSize: 14,
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                Invoice Number
              </Label>
              <input
                style={{
                  height: "40px",
                  width: "400px",
                  padding: "10px 12px",
                  display: "flex",
                  justifyContent: "space-between",
                  color: "black",
                  borderRadius: 100,
                  border: "1px solid #ccc",
                  marginBottom: "20px",
                  backgroundColor: "white",
                }}
                type="number"
                name="invoiceNumber"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
              {invoiceError && (
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    marginBottom: "10px",
                    display: "block",
                  }}
                >
                  {invoiceError}
                </span>
              )}
              <button
                style={{
                  width: "400px",
                  height: "40px",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                  background: "#1F1F1F",
                  borderRadius: 100,
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  display: "inline-flex",
                  cursor: "pointer",
                }}
                disabled={isLoading}
                onClick={handleProceed}
              >
                {isLoading ? "Processing..." : "Process invoice"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <InvoiceDetails
          invoiceNumber={invoiceDetails.invoiceNumber}
          invoiceAmount={invoiceDetails.amount}
          rrr={invoiceDetails.rrr}
          revenue={invoiceDetails.revenueName}
          agencyName={invoiceDetails.agencyName}
          amountPaid={invoiceDetails.amountPaid}
          outstanding={invoiceDetails.outstanding}
          phoneNumber={invoiceDetails.phoneNumber}
          email={invoiceDetails.email}
          location={invoiceDetails.address}
          paymentHistory={paymentHistory}
          agencyId={invoiceDetails.agencyId}
          billerUrl={invoiceDetails.billerUrl}
          merchantId={invoiceDetails.merchantId}
          hash={invoiceDetails.hash}
          responseUrl={invoiceDetails.responseUrl}
          noOfPayments={invoiceDetails.noOfPayments}
          minimumPartPayment={invoiceDetails.minimumPartPayment}
          percentagePartPayment={invoiceDetails.percentagePartPayment}
          deleted={invoiceDetails.deleted}
          status={invoiceDetails.status}
          numberOfPayment={invoiceDetails.numberOfPayment}
          searchInvoiceReference={searchInvoiceReference}
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const {
    alert,
    user,
    requesting,
    userData,
    displayGeneralAlert,
    branding,
    agencyId,
  } = state;
  return {
    alert,
    user,
    requesting,
    userData,
    displayGeneralAlert,
    branding,
    agencyId: state.agency ? state.agency.agencyId : null,
  };
}

export default connect(mapStateToProps)(FaanPayment);
