import { appConstants } from "../_constants";
import { appHelpers } from "../_helpers";

export const externalPaymentService = {
  searchInvoiceDetails,
  getSubInvoiceRRR,
  getRRR,
};

function searchInvoiceDetails(payload) {
    return appHelpers
      .getRequest(
        `${appConstants.PAS_URL}/api/Open/SearchInvoiceBDA?invNum=${payload.invNum}&agencyId=${payload.agencyId}`, 
        null 
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }

  function getSubInvoiceRRR(payload) {
    return appHelpers
      .postRequest(
        `${appConstants.PAS_URL}/api/Open/GenerateSubInvoiceBDA`,
        payload,
      )
      .then((res) => {
        const { error } = res.response;
        if (!error) {
          return res;
        } else {
          return appHelpers.formatPromiseResponse(
            error,
            appConstants.ERROR_RESPONSE
          );
        }
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }
  
  function getRRR(payload) {
    return appHelpers
      .getRequest(
        `${appConstants.PAS_URL}/api/Open/GenerateRRRBDA?invNum=${payload.invNum}`, 
        null 
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      });
  }