import React, { useState, useEffect, useRef } from "react";
import "./faan.scss";
import { alertActions} from "../../_actions";
import { appConstants } from "../../_constants";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import { PaymentHistoryTable } from "./record";
import PartPaymentModal from "./partial-payment-modal";
import PaymentSummary from "./payment-summary-modal";
import { externalPaymentService } from "../../_services/externalPayment.service";
import { store } from 'react-notifications-component';

let source;

const InvoiceDetails = ({
  invoiceAmount,
  invoiceNumber,
  rrr,
  revenue,
  agencyName,
  location,
  phoneNumber,
  email,
  amountPaid,
  outstanding,
  paymentHistory,
  dispatch,
  agencyId,
  billerUrl,
  merchantId,
  hash,
  responseUrl, 
  numberOfPayment,
  minimumPartPayment,
  percentagePartPayment,
  deleted,
  status,
  searchInvoiceReference
}) => {
  const [showPaymentHistory, setShowPaymentHistory] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rrrDetails, setRRRDetails] = useState(null);
  const [isPaymentModal, setIsPaymentModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const invoiceLocal = localStorage.getItem("Invoice Number");
  const formRef = useRef(null);
  const savedInvoice = localStorage.getItem("Invoice Details");

  const searchInvoiceRRR = async () => {
   
    let payload = {
        invNum: invoiceLocal,
    };

    console.log(payload, "OUR payload");
    setIsLoading(true);
    dispatch(alertActions.startRequest());

    try {
      const res = await externalPaymentService.getRRR(payload);
      dispatch(alertActions.stopRequest());

      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        const newRRRDetails = {
            rrr: response.rrr || rrr,
            merchantId: response.merchantId || merchantId,
            hash: response.hash || hash,
            billerUrl: response.billerUrl || billerUrl,
            responseUrl: response.responseUrl || responseUrl,
          };
    
          setRRRDetails(newRRRDetails); // ✅ Update state
              // ✅ Show success notification
      store.addNotification({
        title: "Success",
        message: "Invoice details fetched successfully!",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
      searchInvoiceReference(); 
      console.log("Calling searchInvoiceReference to refetch invoice details...");
    } else if (res.status === appConstants.ERROR_RESPONSE) {
      console.error("API Error Response:", response);

      // ✅ Show error notification
      store.addNotification({
        title: "Error",
        message: response || "An error occurred while generating RRR.",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

      dispatch(alertActions.error(response.message || "Error generating RRR"));
    }
  } catch (error) {
    console.error("generating RRR:", error);

    // ✅ Show error notification for unexpected errors
    store.addNotification({
      title: "Error",
      message: "An unexpected error occurred while generating RRR",
      type: "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
      },
    });

    dispatch(alertActions.error("Error while generating RRR"));
  } finally {
    setIsLoading(false);
  }
};
  const handleGetRRR = async () => {
    await searchInvoiceRRR(amount); // Wait for searchInvoiceRRR to complete
  };

  const handlePartPaymentClick = () => {
    setIsModalOpen(true);
  };



const submitRemitaForm = () => {
    if (formRef.current) {
      // Set form values dynamically
      formRef.current.merchantId.value = merchantId;
      formRef.current.hash.value = hash;
      formRef.current.rrr.value = rrr;
      formRef.current.responseurl.value = responseUrl;
  
      // Set the form action URL correctly
      formRef.current.action = billerUrl;
    // formRef.current.action = "https://login.remita.net/remita/ecomm/finalize.reg"

      // Ensure form submission happens in a user-triggered event
      setTimeout(() => {
        formRef.current.submit();
      }, 500);
    }
  };

const handleFullPaymentClick = async () => {
    if (!rrr) {
      const newRRRDetails = await handleGetRRR(); 
      if (newRRRDetails && newRRRDetails.rrr) {
        setIsPaymentModal(true); 
        return;
      }
    }
  
    // If RRR exists, proceed with payment submission
    submitRemitaForm({
      MerchantId: merchantId,
      Hash: hash,
      RRR: rrr,
      ResponseUrl: responseUrl,
    });
  };
  
  // Use useEffect to trigger form submission when rrrDetails is updated
  useEffect(() => {
    if (rrrDetails && !rrr) {
      submitRemitaForm(rrrDetails); // Submit the form with the new RRR details
    }
  }, [rrrDetails]);
console.log("billerUrl", billerUrl);

const formatCurrency = (amount = 0) => {
  // Create Intl.NumberFormat object with Nigerian currency format
  const formatter = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  });

  // Format the currency
  return formatter.format(amount);
};

  return (
    <div className="invoice-container">
      <button className="go-back" onClick={() => window.location.reload()}>
        ← Go back
      </button>

      <Row>
        <Col md={6}>
          <h2 className="invoice-title">Invoice details</h2>
        </Col>
        <Col md={6} style={{ textAlign: "right" }}>
          <div className="first-row">
            <h1
              style={{
                fontSize: "13px",
                fontWeight: "400",
                color: "rgba(38, 38, 38, 0.80)",
                marginBottom: 0,
              }}
            >
              Invoice amount
            </h1>
            <p className="invoice-title">{formatCurrency(invoiceAmount)}</p>
          </div>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md="3">
          <span className="info-heading">Invoice number</span>
          <br />
          <span className="info-detail">{invoiceNumber}</span>
        </Col>
        <Col md="3">
          <span className="info-heading">RRR</span>
          <br />
          <span className="info-detail">{rrr}</span>
        </Col>
        <Col md="3">
          <span className="info-heading">Revenue</span>
          <br />
          <span className="info-detail">{revenue}</span>
        </Col>
        <Col md="3">
          <span className="info-heading">Agency name</span>
          <br />
          <span className="info-detail">{agencyName}</span>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="3">
          <span className="info-heading">Location</span>
          <br />
          <span className="info-detail">{location}</span>
        </Col>
        <Col md="3">
          <span className="info-heading">Phone number</span>
          <br />
          <span className="info-detail">{phoneNumber}</span>
        </Col>
        <Col md="3">
          <span className="info-heading">Email</span>
          <br />
          <span className="info-detail">{email}</span>
        </Col>
        <Col md="3"></Col>
      </Row>

      <div className="payment-summary">
        <h3>Payment summary</h3>
        <Row className="mb-3">
          <Col md="4">
            <span className="info-heading">Invoice amount</span>
            <br />
            <span className="info-detail">{formatCurrency(invoiceAmount)}</span>
          </Col>
          <Col md="4">
            <span className="info-heading">Amount paid</span>
            <br />
            <span className="info-detail">{formatCurrency(amountPaid)}</span>
          </Col>
          <Col md="4">
            <span className="info-heading">Outstanding</span>
            <br />
            <span className="info-detail">{formatCurrency(outstanding)}</span>
          </Col>
        </Row>
      </div>

      {/* {status === "Unpaid" && deleted === false && ( */}
      {(status === "Unpaid" || status === "Partial Payment") && (
        <div className="button-row">
        <button
          style={{
            width: "400px",
            height: "40px",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: "12px",
            cursor: "pointer",
            border: "1px solid #BFBFBF",
            color: "#000000",
          }}
          onClick={handlePartPaymentClick}
        >
          Partial payment
        </button>
        <button
          style={{
            width: "400px",
            height: "40px",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: "12px",
            cursor: "pointer",
            background: "#1F1F1F",
          }}
          onClick={handleFullPaymentClick}
        >
          {isLoading ? "Processing..." : "Full payment"}
        </button>
      </div>)}

      {paymentHistory && paymentHistory.length > 0 && (
        <div style={{ marginTop: "50px" }}>
          <h3>Payment History</h3>
          <PaymentHistoryTable apiData={paymentHistory} formatCurrency={formatCurrency} />
        </div>
      )}

      {
        <PartPaymentModal
          isOpen={isModalOpen}
          toggle={() => setIsModalOpen(!isModalOpen)}
          outstandingAmount={outstanding}
          agencyId={agencyId} // ✅ Pass agencyId
          numberOfPayment={numberOfPayment}
          minimumPartPayment={minimumPartPayment}
          percentagePartPayment={percentagePartPayment}
          formatCurrency={formatCurrency}
          searchInvoiceReference={searchInvoiceReference}
        />
      }

      {rrrDetails && (
        <PaymentSummary
          isOpen={isPaymentModal}
          toggle={() => setIsPaymentModal(!isPaymentModal)}
          outstandingAmount={outstanding}
          amountToPay={amount}
          rrr={rrrDetails.rrr}
          agencyId={agencyId}
          billerUrl={billerUrl}
          merchantId={rrrDetails.merchantId || merchantId}
          hash={rrrDetails.hash || hash}
          responseUrl={rrrDetails.responseUrl || responseUrl}
          formatCurrency={formatCurrency}
        />
      )}

      {/* Hidden Form for Remita Payment */}
      <form
        ref={formRef}
        action=""
        name="SubmitRemitaForm"
        method="POST"
        target="_blank"
        style={{ display: "none" }}
      >
        <input name="merchantId" type="hidden" />
        <input name="hash" type="hidden" />
        <input name="rrr" type="hidden" />
        <input name="responseurl" type="hidden" />
        <input type="submit" value="Pay via Remita" />
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  const { alert, user, requesting, userData, displayGeneralAlert } = state;
  return {
    alert,
    user,
    requesting,
    userData,
    displayGeneralAlert,
  };
}

export default connect(mapStateToProps)(InvoiceDetails);