import { appConstants } from '../_constants';

const initialState = {
  agencyId: null,
};

export function agency(state = initialState, action) {
  switch (action.type) {
    case appConstants.SET_AGENCYID:
      return {
        ...state,
        agencyId: action.agencyId,
      };
    default:
      return state;
  }
}