import { alertConstants, appConstants } from "../_constants";
import { addLog } from "../_actions/activity";
import { store } from 'react-notifications-component';
import { PUBLIC_ROUTES } from "../_constants";

const activityMiddleware = ({ getState, dispatch }) => next => action => {
  const state = getState();
  
  // ✅ Check if router exists before accessing location
  if (!state.router || !state.router.location) {
    console.error("Router state is undefined. Ensure react-router-redux is set up.");
    return next(action);
  }

  const currentPath = state.router.location.pathname;

  if (PUBLIC_ROUTES.includes(currentPath)) {
    return next(action);
  }
  if (
    action.type !== appConstants.ADD_LOG &&
    action.type !== alertConstants.CLEAR &&
    action.type !== alertConstants.ERROR &&
    action.type !== alertConstants.SUCCESS
  ) {
    let lastActivity = getState().activity;
    if (lastActivity) {
      let now = new Date().getTime();
      let lastSeen = getState().activity.lastSeen;
      let seconds = (now - lastSeen) / 1000;
      if (seconds > appConstants.INACTIVITY_EXPIRATION_LIMIT) {

        // dispatch(alertActions.error("Your session has expired!"));
        store.addNotification({
          // title: "",
          message: "Your session has expired!",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
            // onScreen: true
          }
        });
        /*Open the sign in page*/
        // appHelpers.setSessionErrorMessage(appConstants.AVI_USER_SESSION_EXPIRED_MESSAGE);
        window.location.assign("/login");
      } else {
        // window.alert(1)
        next(action);
      }
    } else {
      // window.alert(2)
      next(action);
    }
    // window.alert(3)
    dispatch(addLog(action.type));
  } else {
    // window.alert(4)
    next(action);
  }
};

export default activityMiddleware;
