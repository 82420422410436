import React, { useState } from "react";
import { alertActions } from "../../_actions";
import { appConstants } from "../../_constants";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import "./faan.scss";
import { externalPaymentService } from "../../_services/externalPayment.service";
import { connect } from "react-redux";
import PaymentSummary from "./payment-summary-modal";
import { store } from "react-notifications-component";

const PartPaymentModal = ({
  isOpen,
  toggle,
  outstandingAmount,
  dispatch,
  agencyId,
  billerUrl,
  merchantId,
  hash,
  responseUrl,
  numberOfPayment,
  minimumPartPayment,
  percentagePartPayment,
  formatCurrency,
  searchInvoiceReference,
}) => {
  const [amount, setAmount] = useState("");
  const [invoiceNo, setInvoiceNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPaymentSummary, setShowPaymentSummary] = useState(false);
  const [getRRR, setGetRRR] = useState("");
  const [rrrDetails, setRRRDetails] = useState("");
 const  invoiceLocal = localStorage.getItem("Invoice Number");
  const [isSuccess, setIsSuccess] = useState(false); // NEW STATE
  const [errors, setErrors] = useState({ amount: "" });


  const validateFields = () => {
    let isValid = true;
    const newErrors = { amount: "" };

    if (!invoiceLocal) {
      dispatch(alertActions.error("Invoice number is missing"));
      isValid = false;
    }

    if (!amount || amount <= 0) {
      newErrors.amount = "Please enter a valid amount";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const searchInvoiceRRR = async (amount) => {
    if (!validateFields()) {
      return;
    }
    let payload = {
      agencyId: agencyId,
      invoiceNumber: invoiceLocal,
      amount: amount,
    };
    setIsLoading(true);
    dispatch(alertActions.startRequest());

    try {
      const res = await externalPaymentService.getSubInvoiceRRR(payload);
      dispatch(alertActions.stopRequest());

      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        setRRRDetails(response);
        setIsSuccess(true); // Show Payment Summary
        searchInvoiceReference();
      } else if (res.status === appConstants.ERROR_RESPONSE) {  
        // ✅ Show error notification
        store.addNotification({
          title: "Error",
          message: response || "An error occurred while generating RRR.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });
  
        dispatch(alertActions.error(response || "Error generating RRR"));
      }
    } catch (error) {
      console.error("generating RRR:", error);
  
      // ✅ Show error notification for unexpected errors
      store.addNotification({
        title: "Error",
        message: "An unexpected error occurred while generating RRR",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });
  
      dispatch(alertActions.error("Error while generating RRR"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetRRR = async () => {
    if (validateFields()) {
      localStorage.setItem("amount", amount);
      await searchInvoiceRRR(amount);
    }
  };
  const resetState = () => {
    setAmount("");
    setErrors({ amount: "" });
    setIsSuccess(false);
  };

  const handleToggle = () => {
    resetState();
    toggle();
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={handleToggle}
      centered
      className="partial-payment-modal"
    >
      {isSuccess ? ( // Toggle between PartPaymentModal and PaymentSummary
        <PaymentSummary
          isOpen={isOpen}
          toggle={handleToggle}
          outstandingAmount={outstandingAmount}
          amountToPay={amount}
          rrr={rrrDetails?.rrr}
          agencyId={agencyId}
          billerUrl={rrrDetails.billerUrl || billerUrl}
          merchantId={rrrDetails.merchantId || merchantId}
          hash={rrrDetails.hash || hash}
          responseUrl={rrrDetails.responseUrl || responseUrl}
          formatCurrency={formatCurrency}
        />
      ) : (
        <>
          <ModalHeader toggle={handleToggle} className="invoice-modal-header">
            Partial payment
          </ModalHeader>
          <ModalBody>
            <div className="main-popup-information">
              <div className="popup-info">
                <p>Outstanding</p>
                <h2 className="info-detail">
                  {formatCurrency(outstandingAmount)}
                </h2>
              </div>
            </div>

            <div className="enter-amount">
              <p>Enter amount</p>
              <input
                type="number"
                placeholder="Enter amount"
                value={amount}
                name="amount"
                onWheel={(e) => e.currentTarget.blur()}
                onChange={(e) => setAmount(e.target.value)}
              />
           {errors.amount && <span style={{ color: "red"}}>{errors.amount}</span>}

            </div>

            <div className="terms-of-payment">
              <h5>Terms of payment</h5>
              <ul>
                <li>
                  Minimum payable amount is {percentagePartPayment}% of
                  outstanding amount which is{" "}
                  {formatCurrency(outstandingAmount)}
                </li>
                <li>
                  Minimum invoice amount is {formatCurrency(minimumPartPayment)}
                </li>
                <li>
                  Maximum number of payments is {numberOfPayment} times
                </li>
              </ul>
            </div>
          </ModalBody>
          <ModalFooter className="invoice-modal-footer">
            <Button
              color="light"
              onClick={() => {
                handleToggle();
                setAmount("");
              }}
              className="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              className="proceed-btn"
              onClick={handleGetRRR}
              disabled={isLoading}
            >
              {isLoading ? "..loading" : "Proceed"}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
const mapStateToProps = (state) => ({
  alert: state.alert,
  user: state.user,
  requesting: state.requesting,
  userData: state.userData,
  displayGeneralAlert: state.displayGeneralAlert,
});

export default connect(mapStateToProps)(PartPaymentModal);
