export const appConstants = {
  USER_KEY: 'persist:root',
  AVI_USER_SESSION_EXPIRED: 'avi-user-session-expired',
  AVI_USER_SESSION_ERROR: 'avi-user-session-error',
  AVI_USER_SESSION_UNAUTHORISED_ERROR_MESSAGE:
    'You do not have privilege to access page!',
  AVI_USER_SESSION_EXPIRED_MESSAGE: 'Your session has expired!',
  AVI_USER_SESSION_AUTHENTICATION_REQUIRED_MESSAGE:
    'You need to login to access page!',
  APP_USER_KEY: 'psc-user',
  APP_USER_SESSION_EXPIRED: 'avi-user-session-expired',
  APP_USER_SESSION_ERROR: 'avi-user-session-error',
  APP_USER_SESSION_UNAUTHORISED_ERROR_MESSAGE:
    'You do not have the permission to access page!',
  APP_USER_SESSION_UNAUTHORISED_ERROR_MESSAGE_2:
    'You have not been provision to access this system',
  APP_USER_SESSION_EXPIRED_MESSAGE: 'Your session has expired!',
  APP_USER_SESSION_AUTHENTICATION_REQUIRED_MESSAGE:
    'You need to signin to access page!',
  APP_INTERNET_CONNECTION_MESSAGE: 'Please Check your  internet connectivity',
  INACTIVITY_EXPIRATION_LIMIT: 3600,

  //API URLS
  PAS_URL:
    process.env.NODE_ENV === 'development'
      ? 'https://passerelle.test.vggdev.com'
      : window.env.passerelleUrl,
  ENV_ID: process.env.NODE_ENV === 'development' ? 'test' : window.env.envId,
  APP_ID: 'BDA-Web',
  FORGOT_PASSWORD_URL:
    process.env.NODE_ENV === 'development'
      ? 'https://usermanager.test.vggdev.com/Account/ForgotPassword'
      : window.env.forgotPasswordUrl,
  MAKER_CHECKER_APP_ID:
    process.env.NODE_ENV === 'development' ? 10 : window.env.makerCheckerAppId,

  APP_URL:
    process.env.NODE_ENV === 'development'
      ? 'https://bda-new.staging.vggdev.com'
      : window.env.appUrl,
  FAQ_URL: (process.env.NODE_ENV === 'development') ? "https://tranzitechfaqapi.test.vggdev.com" : window.env.faqUrl,
  // APP_URL: process.env.NODE_ENV === "development" ? "http://bda-new.staging.vggdev.com" : window.env.appUrl,
  DAMZEE_USER_ID: '6735E61C-3EFF-4FCF-9730-0A1AA0DB1EEB',
  HENRY_USER_ID: '897F1A97-22A2-4611-A2AD-14AF59EA3BFC',
  ORG_ID: 20,
  // APP_ID: "37AB0379-852C-4183-AED4-803D04CCBC23",
  MAKER_CHECKER_STATUS: {
    PENDING: 0,
    APPROVED: 1,
    REJECTED: 2,
  },

  //Action Types
  ADD_LOG: 'add_log',
  OPEN_MODAL: 'open_modal',
  CLOSE_MODAL: 'close_modal',
  CLEAR_MODAL: 'clear_modal',
  //API Status
  API_REQUEST_START: 'API_REQUEST_START',
  API_REQUEST_FULL_PAGE_START: 'API_REQUEST_FULL_PAGE_START',
  API_REQUEST_FINISH: 'API_REQUEST_FINISH',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_FAILURE: 'REQUEST_FAILURE',
  GET_REQUEST: 'get',
  POST_REQUEST: 'post',

  NOTIFY_SUCCESS: 'success',
  NOTIFY_ERROR: 'error',
  NOTIFY_INFO: 'info',
  NOTIFY_INVALID: 'in',
  NOTIFY_WARNING: 'warning',
  NOTIFY_DANGER: 'danger',

  SUCCESS_RESPONSE: 1,
  ERROR_RESPONSE: 2,
  NOT_FOUND_RESPONSE: 3,

  SUCCESS: 'ALERT_SUCCESS',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR',

  /*Period Picker Tabs*/
  THISWEEK: 'WEEK',
  TODAY: 'TODAY',
  THISMONTH: 'MONTH',

  /*Colors*/
  PRIMARY_COLOR: '#2c0e73',
  INFO_COLOR: '#00acc1',
  WARNING_COLOR: '#fb8c00',
  MUTED_COLOR: '#3b3b3b',
  DANGER_COLOR: '#f44336',
  SUCCESS_COLOR: '#08EB6B',
  PERIODPICKER_SELECTED_COLOR: '#ffffff80',
  PERIODPICKER_BG_COLOR: 'transparent',
  TOTALREQUESTS_ACTIVE: 'rgb(41,146,208)',
  TOTALREQUESTS_INACTIVE: 'rgba(41,146,208, 0.1)',
  APPROVEDREQUEST_ACTIVE: 'rgb( 64, 217, 153)',
  APPROVEDREQUESTS_INACTIVE: 'rgba( 64, 217, 153, 0.1)',
  DECLINEDREQUESTS_ACTIVE: 'rgb(245,74,52)',
  DECLINEDREQUESTS_INACTIVE: 'rgba(245,74,52, 0.1)',
  UNATTENDEDQUESTS_ACTIVE: 'rgb(201,108,172)',
  UNATTENDEDQUESTS_INACTIVE: 'rgba(201,108,172, 0.1)',

  GETINVOICEANDPAYMENTPERREVENUE: 'GetInvoiceAndPaymentPerRevenue',
  PERIOD_PICKER_SELECTED: 'PERIOD_PICKER_SELECTED',

  //PAGE TITLES
  CREATE_ROLE: 'Create Role',
  EDIT_ROLES: 'Edit Roles & Permissions',
  ROLES_AND_PERMISSIONS: 'Roles and Permissions',
  ROLE_PROFILE: 'Role Profile',
  EDIT_USER: 'Edit User',
  CREATE_USER: 'Create New User',
  USER_PROFILE: 'User Profile',
  EDIT_STAFF: 'Edit Staff',
  STAFF_PROFILE: 'Staff Profile',
  MANAGE_USERS: 'Manage Users',
  ADD_NEW_ACCOUNT: 'Add New Account',
  EDIT_ACCOUNT: 'Edit Account Information',
  MANAGE_STAFFS: 'Manage Staffs',
  CREATE_STAFF: 'Create New Staff',
  CREATE_SETUP: 'Create Self-Service Setup',
  EDIT_SETUP: 'Edit Self-Service Setup',
  REVIEW_SETUP: 'Review Self-Service Setup',
  CREATE_INVOICE: 'Create New Invoice',
  EDIT_INVOICE: 'Edit Invoice',
  MANAGE_WORKFLOW_APPROVAL: 'Manage Workflow Approval',

  /*user Roles*/
  ROLES: {
    // SUPER_ADMIN: "Super Admin",
    CLIENT_ADMIN: 'clientadmin',
    CLIENT_USER: 'clientuser',
    VGG_ADMIN: 'vgg_admin',
    VGG_USER: 'vgg_user',
    CUSTOMER_ADMIN: 'Customer Admin',
    LOCATION_USER: 'Location User',
  },

  ROLES_PAGES: {
    SUPER_ADMIN: ['*'],
    // CLIENT_ADMIN: ["login", "dashboard", "create-invoice", "search-invoice", "void-requests", "audit-trails", "settings", "settings-create-user", "manage-users"],
    CLIENT_ADMIN: ['*'],
    CLIENT_USER: [],
    VGG_ADMIN: [],
    VGG_USER: [],
  },
  VGG_ROLES: [
    { name: 'Client Admin', id: 'clientadmin' },
    { name: 'Client User', id: 'clientuser' },
    { name: 'Vgg Admin', id: 'vgg_admin' },
    { name: 'Vgg User', id: 'vgg_user' },
  ],
  CLIENT_ROLES: [
    { name: 'Client Admin', id: 'clientadmin' },
    { name: 'Client User', id: 'clientuser' },
  ],

  //actions
  ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
  USERS_SUCCESS: 'GET_USERS_SUCCESS',
  AIRLINES_SUCCESS: 'GET_AIRLINES_SUCCESS',
  AIRPORTS_SUCCESS: 'GET_AIRPORTS_SUCCESS',
  SIGN_IN_SUCCESS: 'POST_SIGN_IN_SUCCESS',
  SIGN_OUT: 'SIGN_OUT',
  ADD_DASHBOARD: 'ADD_DASHBOARD',
  REGIONS_SUCCESS: 'REGIONS_SUCCESS',
  STATE_SUCCESS: 'STATE_SUCCESS',
  ORGANIZATION_SUCCESS: 'ORGANIZATION_SUCCESS',
  GET_ENTITIES: 'GET_ENTITIES',
  COUNTRIES_SUCCESS: 'COUNTRIES_SUCCESS',
  USER_DATA: 'USER_DATA',
  SAVE_SELECTED_LOCATION_IDS: 'SAVE_SELECTED_LOCATION_IDS',
  SET_AGENCYID: 'SET_AGENCYID',

  PDF_DOWNLOAD_TYPE: 1,
  EXCEL_DOWNLOAD_TYPE: 0,

  CLEAR_AIRPORTS: 'CLEAR_AIRPORTS',
  CLEAR_AIRLINES: 'CLEAR_AIRLINES',
  SET_PERIOD_PICKER_VISIBILITY: 'SET_PERIOD_PICKER_VISIBILITY',
  SET_WELCOME_SCREEN_VISIBILITY: 'SET_WELCOME_SCREEN_VISIBILITY',
  SHOW_MINI_SIDEBAR_DROPDOWN: 'SHOW_MINI_SIDEBAR_DROPDOWN',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  SHOW_RIGHT_SIDEBAR: 'SHOW_RIGHT_SIDEBAR',
  BASE_ROUTE: 'BASE_ROUTE',
  DISPLAY_FULL_OVERLAY: 'DISPLAY_FULL_OVERLAY',
  DISPLAY_GENERAL_ALERT: 'DISPLAY_GENERAL_ALERT',
  DISPLAY_SECONDARY_ALERT: 'DISPLAY_SECONDARY_ALERT',
  GET_ROUTES: 'GET_ROUTES',
  GATEWAY_CODE: 'GATEWAY_CODE',
  DEFAULT_PRECISION: 2,
};

export const PUBLIC_ROUTES = [
  '/invoice-payment',
  // Add any other public routes here
];