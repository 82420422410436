import React from "react";
import { Table } from "reactstrap";
import moment from "moment";
import './faan.scss'

export const PaymentHistoryTable = ({ apiData, formatCurrency }) => {
  return (
    <Table responsive={true} className={"table-main"}>
      <thead style={{ borderBottom: "2px solid black" }}>
        <tr>
          <th style={{ padding: "none" }}>Amount</th>
          <th style={{ padding: "none" }}>Invoice no</th>
          <th style={{ padding: "none" }}>RRR</th>
          <th style={{ padding: "none" }}>Payment date</th>
          <th style={{ padding: "none" }}>Status</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item, index) => {
          return (
            <tr
              key={index}
              style={{
                borderTop: "12px solid transparent",
                borderBottom: "12px solid transparent",
              }}
            >
              <th>{formatCurrency(item.amount)}</th>
              <th>{item.invoiceNumber}</th>
              <th>{item.rrr}</th>
              <th> {moment(item.createdOn).format("YYYY-MM-DD")}</th>
              <th>
                <span
                  className={`status-label ${item.isPaid ? "paid" : "unpaid"}`}
                >
                  {item.isPaid ? "Paid" : "Unpaid"}
                </span>
              </th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const TableFormat = ({ apiData, formatCurrency }) => {
  return (
    <div>
      <PaymentHistoryTable apiData={apiData} formatCurrency={formatCurrency} />
    </div>
  );
};

export default TableFormat;
